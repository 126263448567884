<template>
  <div class="viewcontent">
    <h1>
      {{ translations.title_error_404 }}
    </h1>
    <p>{{ translations.error_404 }}</p>
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  export default {
    name: 'NotFound',

    computed: {
      ...mapState(['translations']),
    },
  }
</script>

<style></style>
